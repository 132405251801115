import React from 'react'

export default function TailwindInit() {
  return (
    <div className="opacity-0">
      <div className="bg-custom-orange" />
      <div className="bg-custom-red" />
      <div className="bg-custom-violet" />
      <div className="bg-custom-green" />
      <div className="bg-custom-green-dark" />
      <div className="bg-custom-blue" />
    </div>
  );
}
