import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  applyCurrencyFormat,
  applyDateFormat,
} from "../../utils/datatableHelper";
import Tag from "../../components/Tag";
import Search from "../../components/Datatable/Search";
import Filter from "../../components/Datatable/Filter";
import { useInvestorContext } from "../../contexts/InvestorContext";
import { Button } from "primereact/button";
import { MEDIAS } from "../../ressources/MEDIAS";

export const InvestorDatatable = ({
  loading,
  data,
  selectItem,
  intermediateList,
  handleCreateInvestor,
}) => {
  const exportRef = useRef(null);

  const { getAuthorizedScopeList, getScopeFromId } = useAuthorizationsContext();
  const {
    investorContext,
    setInvestorFilter,
    setInvestorOptions,
    setInvestorSort,
  } = useInvestorContext();

  const handleFilter = (key, value) => {
    setInvestorFilter(key, value);
  };

  const onPage = (event) => {
    setInvestorOptions({
      page: event.page,
      offset: event.first,
      rows: event.rows,
    });
  };

  const onSort = (sort) => {
    setInvestorSort(sort);
  };

  const intermediateBodyTemplate = (rowData) => {
    if (!rowData.intermediate) return "";
    return (
      <Tag value={rowData.intermediate.name} icon={MEDIAS.briefcaseWhite} />
    );
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-nowrap gap-x-2">
        {rowData.linked_scopes.map((lsId) => (
          <Tag
            key={lsId}
            value={getScopeFromId(lsId).label}
            bgColor={"bg-" + getScopeFromId(lsId).main_color}
            rounded
          />
        ))}
      </div>
    );
  };

  const pendingTransactionNbBodyTemplate = (rowData) => {
    if (!rowData.pending_transaction_nb) return "";
    return (
      <Tag
        value={`${rowData.pending_transaction_nb} en attente`}
        bgColor={"bg-custom-orange"}
        rounded
      />
    );
  };

  const filters = (
    <div className="flex flex-row gap-x-3">
      <Search
        placeholder="Filtrer par nom & prénom"
        handleSearch={(value) => handleFilter("query", value)}
        value={investorContext.filters.query}
      />
      <Filter
        key="scope"
        options={getAuthorizedScopeList()}
        value={investorContext.filters.scopeList}
        onChange={(value) => handleFilter("scopeList", value)}
        emptyLabel="Filtrer par périmètre"
      />
      <Filter
        key="intermediate"
        options={intermediateList}
        value={investorContext.filters.intermediateList}
        onChange={(value) => handleFilter("intermediateList", value)}
        emptyLabel="Filtrer par intermédiaire"
        optionLabel="name"
      />
    </div>
  );

  const actions = (
    <div className="flex flex-row gap-x-3">
      <Button
        key={"csv"}
        size={"small"}
        onClick={() => exportRef.current.exportCSV()}
        icon={"pi pi-cloud-download"}
        label={"Exporter la vue actuelle"}
        outlined
      />
      {handleCreateInvestor && (
        <Button
          key={"investor"}
          size={"small"}
          onClick={handleCreateInvestor}
          icon={"pi pi-plus"}
          label={"Nouveau"}
        />
      )}
    </div>
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" frozen />
        <Column footer={`${data.total_investor} investisseurs`} frozen />
        <Column footer={`(${data.total_active_investor} actifs)`} />
        <Column />
        <Column />
        <Column />
        <Column footer={`${applyCurrencyFormat(data.total_balance)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_ps_balance)}`} />
        <Column footer={`${data.total_ps_share_balance}`} />
        <Column footer={`${applyCurrencyFormat(data.total_tp_balance)}`} />
        <Column footer={`${data.total_tp_share_balance}`} />
        <Column footer={`${applyCurrencyFormat(data.total_cc_balance)}`} />
        <Column />
        <Column />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between w-full">
        {filters}
        {actions}
      </div>
      <DataTable
        ref={exportRef}
        value={data.investor_list}
        emptyMessage={"Pas d'investisseur à afficher"}
        scrollable={true}
        paginator
        loading={loading}
        rows={investorContext.options.rows}
        first={investorContext.options.offset}
        rowsPerPageOptions={[5, 20, 50, 100, 200, 500]}
        totalRecords={data.total_investor}
        onPage={onPage}
        lazy
        onSort={onSort}
        sortMode={"single"}
        sortField={investorContext.sortOption.sortField}
        sortOrder={investorContext.sortOption.sortOrder}
        selectionMode="single"
        onRowSelect={(e) => selectItem(e.data)}
        exportFilename={`export_investisseurs_${moment().format("DD/MM/YYYY")}`}
        csvSeparator=";"
        footerColumnGroup={loading ? null : footerGroup}
      >
        <Column className="font-bold" field="name" header="Nom" frozen></Column>
        <Column
          className="font-bold"
          field="first_name"
          frozen
          header="Prénom"
        ></Column>
        <Column
          field="linked_scopes"
          header="Périmètre(s)"
          body={scopesBodyTemplate}
        ></Column>
        <Column
          sortable
          field="pending_transaction_nb"
          header="Transactions"
          body={pendingTransactionNbBodyTemplate}
        ></Column>
        <Column field="college" header="Collège"></Column>
        <Column field="profile" header="Profil"></Column>
        {/* Hidden columns, for CSV export*/}
        <Column hidden field="gender" header="Civilité"></Column>
        <Column hidden field="city" header="Ville"></Column>
        <Column hidden field="zip_code" header="Code postal"></Column>
        <Column hidden field="address" header="Adresse"></Column>
        <Column hidden field="email" header="Email"></Column>
        <Column hidden field="phone" header="Téléphone"></Column>
        <Column hidden field="birth_place" header="Lieu de naissance"></Column>
        <Column hidden field="birth_dt" header="Date de naissance"></Column>
        {/* End of Hidden columns*/}
        <Column
          sortable
          dataType="numeric"
          field="balance"
          header="Solde Total"
          body={(rowData) => applyCurrencyFormat(rowData.balance)}
        ></Column>
        <Column
          sortable
          dataType="numeric"
          field="ps_balance"
          header="Solde PS"
          body={(rowData) => applyCurrencyFormat(rowData.ps_balance)}
        ></Column>
        <Column
          sortable
          dataType="numeric"
          field="ps_share_balance"
          header="Nb PS"
        ></Column>
        <Column
          sortable
          dataType="numeric"
          field="tp_balance"
          header="Solde TP"
          body={(rowData) => applyCurrencyFormat(rowData.tp_balance)}
        ></Column>
        <Column
          sortable
          dataType="numeric"
          field="tp_share_balance"
          header="Nb TP"
        ></Column>
        <Column
          sortable
          dataType="numeric"
          field="cc_balance"
          header="Solde CC"
          body={(rowData) => applyCurrencyFormat(rowData.cc_balance)}
        ></Column>
        <Column
          field="intermediate.name"
          header="Intermédiaire"
          body={intermediateBodyTemplate}
        ></Column>
        <Column
          body={(rowData) => applyDateFormat(rowData.first_investment_dt)}
          field="first_investment_dt"
          header="Premier invest."
          dataType="date"
        ></Column>
        <Column
          body={(rowData) => applyDateFormat(rowData.last_investment_dt)}
          field={"last_investment_dt"}
          header="Dernier invest."
          dataType="date"
        ></Column>
        <Column sortable field="id" header="ID"></Column>
      </DataTable>
    </div>
  );
};
